import archeologieRaakvlakTile from "./p_archeologie-raakvlak.png";
import cirkelheldenTile from "./p_cirkelhelden-bibapen.png";
import concertgebouwbruggeTile from "./p_concertgebouwbrugge.png";
import erfgoedklasbakkenTile from "./p_erfgoedklasbakken.png";
import gezondLevenPakketTile from "./p_gezondleven-pakket.png";
import gezondLevenSpelTile from "./p_gezondleven-spel.png";
import gezondLevenToolkitTile from "./p_gezondleven-toolkit.png";
import kazouTile from "./p_kazou.png";
import kovZillTile from "./p_kov-zill.png";
import mucoTile from "./p_muco.png";
import passieTile from "./p_passie.png";
import textureTile from "./p_texture.png";
import optilTile from "./p_toolkit-optil.png";
import billenkarrenspelTile from "./p_billenkarrenspel.png";
import conceptkaartenTile from "./p_conceptkaarten.png";
import edoTile from "./p_edo.png";
import grammaticaAnimatiesTile from "./p_grammatica-animaties.png";
import heldenflixTile from "./p_heldenflix.png";
import syntheseAnimatiesTile from "./p_synthese-animaties.png";
import fonemiTile from "./p_fonemi.png";
import breingravenTile from "./p_breingraven.png";
import teletijdvitrineTile from "./p_museabrugge_teletijdvitrine.png";
import muzischeTriptiekenTile from "./p_museabrugge_digitalekijklessen.png";
import curatorenGezochtTile from "./p_museabrugge_groeningespel.png";
import welkomInOoitkampTile from "./p_museabrugge_volskundespel.png";
import stanlieTile from "./p_stanlie.png";
import erfgoednachtspelTile from "./p_erfgoednachtspel.png";
import oogInOogTile from "./p_museabrugge_rondleiding.png";
import musicoTourTile from "./p_muco2.png";
import zeewindInDeKlasTile from "./p_horizon.png";
import geronimoLeeschallengeTile from "./p_geronimo.png";
import tabelMendeljevTile from "./p_tabelmendeljev.png";
import erfgoedScienceLabTile from "./p_erfpunt-science-lab.png";
import pardoesLXDTile from "./p_pardoes-experiences.png";
import tijdreizigersTile from "./p_tijdreizigers.png";
import reflexTile from "./p_edis-reflex-5-guided-tours.png";
import mediascannersTile from "./p_ehc3-mediascanners.png";
import sciencelabTile from "./p_erfpunt-science-lab.png";
import landscapevoicesTile from "./p_fiets-landscape-voices.png";
import bunkerTile from "./p_bunker.png";
import drawForChangeTile from "./p_drawforchange.png";
import historischeHuizenTile from "./p_historischehuizen-heksen.png";
import maakTeamTile from "./p_maakteam.png";
import klimaxTile from "./p_klimax.png";
import archeoTile from "./p_123-archeologie-vondstenfluisteraars.png";
import belfortTile from "./p_belfort-belfortbonzen.png";
import sproetjeTile from "./p_muco3-sproetje.png";
import passchendaeleTile from "./p_passchendaele.png";
import sintjansTile from "./p_sintjans.png";
import blokkadesTile from "./p_blokkades.png";
import e17Tile from "./p_E17.png";
import enameTile from "./p_ename.png";
import gezondLevenThuisToolsTile from "./p_gezondleven_thuistools.png";
import deReuzenTile from "./p_mechelen_DeReuzen.png";
import sinteMetteTile from "./p_mechelen_SinteMette.png";
import meesterwerkenTile from "./p_meesterwerken_van_de_zee.png";
import familieParcoursTile from "./p_ph_familieparcours.png";
import toolkitJeugdruimteTile from "./p_toolkit_jeugdruimte.png";
import transfoTile from "./p_transfo.png";
import beiaardTile from "./p_beiaardcultuur.png";
import humorTile from "./p_humorindezorg.png";
import museaTile from "./p_musea brugge3.png";
import stadsschouwburgTile from "./p_stadsschouwburg.png";
import stamTile from "./p_stam.png";
import transfo2Tile from "./p_transfo2.png";

export const caseTiles = {
    billenkarrenspelTile,
    conceptkaartenTile,
    edoTile,
    grammaticaAnimatiesTile,
    heldenflixTile,
    syntheseAnimatiesTile,
    archeologieRaakvlakTile,
    cirkelheldenTile,
    concertgebouwbruggeTile,
    erfgoedklasbakkenTile,
    gezondLevenPakketTile,
    gezondLevenSpelTile,
    gezondLevenToolkitTile,
    kazouTile,
    kovZillTile,
    mucoTile,
    passieTile,
    textureTile,
    optilTile,
    fonemiTile,
    breingravenTile,
    teletijdvitrineTile,
    muzischeTriptiekenTile,
    curatorenGezochtTile,
    welkomInOoitkampTile,
    stanlieTile,
    erfgoednachtspelTile,
    oogInOogTile,
    musicoTourTile,
    zeewindInDeKlasTile,
    geronimoLeeschallengeTile,
    tabelMendeljevTile,
    erfgoedScienceLabTile,
    pardoesLXDTile,
    tijdreizigersTile,
    reflexTile,
    mediascannersTile,
    sciencelabTile,
    landscapevoicesTile,
    bunkerTile,
    drawForChangeTile,
    historischeHuizenTile,
    maakTeamTile,
    klimaxTile,
    archeoTile,
    belfortTile,
    sproetjeTile,
    passchendaeleTile,
    sintjansTile,
    blokkadesTile,
    e17Tile,
    enameTile,
    gezondLevenThuisToolsTile,
    deReuzenTile,
    sinteMetteTile,
    meesterwerkenTile,
    familieParcoursTile,
    toolkitJeugdruimteTile,
    transfoTile,
    beiaardTile,
    humorTile,
    museaTile,
    stadsschouwburgTile,
    stamTile,
    transfo2Tile,
};